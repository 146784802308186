import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import Privacy from '../components/Privacy/Privacy';
import MainLayout from '../layaouts/MainLayout/MainLayout';

function PrivacyPage({ pageContext }) {
  const {
    data,
  } = pageContext;

  return (
    <MainLayout>
      <Privacy data={data} />
    </MainLayout>
  );
}

export default injectIntl(PrivacyPage);
