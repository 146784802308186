/* eslint-disable camelcase */
import React from 'react';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import MetaTags from '../Header/MetaTags';
import './style.css';
import FileIcon from '../../assets/icons/file.svg';
import Button from '@material-ui/core/Button';

export default function Privacy({ data }) {
  const intl = useIntl();
  console.log(data[intl.locale]);
  const {
    filesSectionTitle,
    privacyPolicyDescription,
    privacyPolicyPdfFiles,
    privacyPolicyTitle,
  } = data[intl.locale].acf.privacyPolicyData;
  const { yoast_head_json } = data[intl.locale];

  return (
    <>
      <MetaTags data={yoast_head_json} />
      <div id="privacy">
        <div className="blu-space" />
        <div className="privacy-body">
          <h1 className="privacyTitle">{privacyPolicyTitle}</h1>
          <div
            className="privacyContent"
            dangerouslySetInnerHTML={{ __html: privacyPolicyDescription }}
          />
          <div className="privacyPDFs-container">
            <div className="privacyPDFs-title">{filesSectionTitle}</div>
            {/* <Link to="/benefici-fiscali">
              <Button>Benefici fiscali</Button>
            </Link> */}
            <div className="privacyPDFs-list">
              {privacyPolicyPdfFiles !== undefined
                && privacyPolicyPdfFiles.length > 0
                && privacyPolicyPdfFiles.map((file) => (
                  <div
                    className="file"
                    onClick={() => window.open(file.privacyPolicyFile.url, '_blank')}
                  >
                    <div className="file-icon">
                      <img
                        className="file-icon-img"
                        alt="file-icon"
                        src={FileIcon}
                      />
                    </div>
                    <div className="file-name">
                      {file.privacyPolicyFileTitle !== ''
                        ? file.privacyPolicyFileTitle
                        : file.privacyPolicyFile.title}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
